import { default as attachedlistOvWL1fk2lOMeta } from "/home/ecs-user/smart-platform/pages/attachedlist.vue?macro=true";
import { default as dashboardDSZu8Dy9loMeta } from "/home/ecs-user/smart-platform/pages/dashboard.vue?macro=true";
import { default as equipmentbAjTb3iTvxMeta } from "/home/ecs-user/smart-platform/pages/equipment.vue?macro=true";
import { default as indexhQc86JNO1GMeta } from "/home/ecs-user/smart-platform/pages/index.vue?macro=true";
import { default as inputdataQ1T35Vjkz6Meta } from "/home/ecs-user/smart-platform/pages/inputdata.vue?macro=true";
import { default as inspectresultx6FZAijDVLMeta } from "/home/ecs-user/smart-platform/pages/inspectresult.vue?macro=true";
import { default as mandatoryparam2Ox8SYgk0mMeta } from "/home/ecs-user/smart-platform/pages/mandatoryparam.vue?macro=true";
import { default as metricJiTwMehfZ4Meta } from "/home/ecs-user/smart-platform/pages/metric.vue?macro=true";
import { default as metricdata12G2lcoguOMeta } from "/home/ecs-user/smart-platform/pages/metricdata.vue?macro=true";
import { default as positionw2sF5pN3fkMeta } from "/home/ecs-user/smart-platform/pages/position.vue?macro=true";
import { default as repairtask87UurpjogOMeta } from "/home/ecs-user/smart-platform/pages/repairtask.vue?macro=true";
import { default as rolen4BrwrxoXwMeta } from "/home/ecs-user/smart-platform/pages/role.vue?macro=true";
import { default as siteCDnAKwoiKwMeta } from "/home/ecs-user/smart-platform/pages/site.vue?macro=true";
import { default as sparepart6iCLLFNEceMeta } from "/home/ecs-user/smart-platform/pages/sparepart.vue?macro=true";
import { default as table1RNmbaweyjMeta } from "/home/ecs-user/smart-platform/pages/table.vue?macro=true";
import { default as tableview1K6McAa46GMeta } from "/home/ecs-user/smart-platform/pages/tableview.vue?macro=true";
export default [
  {
    name: "attachedlist",
    path: "/attachedlist",
    component: () => import("/home/ecs-user/smart-platform/pages/attachedlist.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/ecs-user/smart-platform/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/home/ecs-user/smart-platform/pages/equipment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ecs-user/smart-platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inputdata",
    path: "/inputdata",
    component: () => import("/home/ecs-user/smart-platform/pages/inputdata.vue").then(m => m.default || m)
  },
  {
    name: "inspectresult",
    path: "/inspectresult",
    component: () => import("/home/ecs-user/smart-platform/pages/inspectresult.vue").then(m => m.default || m)
  },
  {
    name: "mandatoryparam",
    path: "/mandatoryparam",
    component: () => import("/home/ecs-user/smart-platform/pages/mandatoryparam.vue").then(m => m.default || m)
  },
  {
    name: "metric",
    path: "/metric",
    component: () => import("/home/ecs-user/smart-platform/pages/metric.vue").then(m => m.default || m)
  },
  {
    name: "metricdata",
    path: "/metricdata",
    component: () => import("/home/ecs-user/smart-platform/pages/metricdata.vue").then(m => m.default || m)
  },
  {
    name: "position",
    path: "/position",
    component: () => import("/home/ecs-user/smart-platform/pages/position.vue").then(m => m.default || m)
  },
  {
    name: "repairtask",
    path: "/repairtask",
    component: () => import("/home/ecs-user/smart-platform/pages/repairtask.vue").then(m => m.default || m)
  },
  {
    name: "role",
    path: "/role",
    component: () => import("/home/ecs-user/smart-platform/pages/role.vue").then(m => m.default || m)
  },
  {
    name: "site",
    path: "/site",
    component: () => import("/home/ecs-user/smart-platform/pages/site.vue").then(m => m.default || m)
  },
  {
    name: "sparepart",
    path: "/sparepart",
    component: () => import("/home/ecs-user/smart-platform/pages/sparepart.vue").then(m => m.default || m)
  },
  {
    name: "table",
    path: "/table",
    component: () => import("/home/ecs-user/smart-platform/pages/table.vue").then(m => m.default || m)
  },
  {
    name: "tableview",
    path: "/tableview",
    component: () => import("/home/ecs-user/smart-platform/pages/tableview.vue").then(m => m.default || m)
  }
]